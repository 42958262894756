import React, {useEffect, useState, useContext, useCallback} from 'react';
import IconSituacaoMensagemWhatsapp from '../IconSituacaoMensagemWhatsapp';
import { AtendentesContext } from '../../contexts/AtendentesContext';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import RecebeImages from '../Images';
import RecebeVideo from '../Video';
import RecebeAudio from '../Audio'; 
import FileDownload from '../FileDownload';
import moment from "moment";
import { WebSocketContext } from "../../contexts/WebSocketContext";

export default function MensagemConteudoAtendimentoWhatsapp({conteudo}){
  const {resolverNomeAtendente} = useContext(AtendentesContext);
  const [detalhes, setDetalhes] = useState({});
  const [cadastradoPor, setCadastradoPor] = useState(null);
  const { channel } = useContext(WebSocketContext);
  const {atendimentoAtivo} = useContext(AtendimentoContext);

  useEffect(() => {
    if(typeof conteudo.detalhes === 'string'){
      const novoDetalhes = JSON.parse(conteudo.detalhes);
      setDetalhes(novoDetalhes);

    }else{
      const novoDetalhes = conteudo.detalhes || {};
      setDetalhes(novoDetalhes);

    }
  }, [conteudo.detalhes]);

  useEffect(() => {
    const cadastrado_por = (conteudo.direcao === 'enviado' && resolverNomeAtendente(conteudo.criado_por)) || (atendimentoAtivo.nome || "Destinatário");
    setCadastradoPor(cadastrado_por);
  }, [conteudo.criado_por, atendimentoAtivo]);

  useEffect(() => {
    if(channel && conteudo && conteudo.id && detalhes){
      channel.unbind('situacaoMensagemAtualizada.' + conteudo.id);
      channel.bind('situacaoMensagemAtualizada.' + conteudo.id, function (data) {
          const novoDetalhes = Object.assign({}, detalhes);
          novoDetalhes.situacao = data.situacao;
          setDetalhes(novoDetalhes);
      });
    }
  }, [channel, conteudo, detalhes, setDetalhes])

  const resolverConteudoMensagem = useCallback(()=>{
    let msgContent;
    switch (detalhes.type) {
        case 'img':
        case 'imagem':  
            msgContent =
                <RecebeImages conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'video':
            msgContent =
                <RecebeVideo conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'audio':
            msgContent =
                <RecebeAudio conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'file':
        case 'msword':
        case 'pdf':
            if(detalhes.url) {
              msgContent = <FileDownload conteudo={detalhes.nome_arquivo || ("Anexo " +(((conteudo.direcao === 'recebido') && 'recebido') || 'enviado'))} url={detalhes.url} />
            } else {
              msgContent = <div dangerouslySetInnerHTML={{__html: conteudo.conteudo }}></div>
            }
            break;
        case 'text':
        default:
            msgContent = <div dangerouslySetInnerHTML={{__html: conteudo.conteudo }}></div>
            break;
    }

    return msgContent
  }, [detalhes.type, conteudo.conteudo]);

  return <>
      <div className={'data ' + ((conteudo && conteudo.direcao) === 'recebido' ? 'recebida' : 'enviada')}>
          {cadastradoPor} - {moment(conteudo.criado_em).format('HH:mm')} {detalhes && detalhes.situacao && <IconSituacaoMensagemWhatsapp situacao={detalhes.situacao} detalhes={detalhes} />}
      </div>
      <div
        className={'mensagem-item text-dark ' + (conteudo.direcao === 'recebido' ? 'recebida' : 'enviada')} >
          {resolverConteudoMensagem()}
      </div>
  </>
}
