import { useContext } from "react";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";

export function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <div
        style={{
          backgroundColor: isCurrentEventKey ? "#265d9c" : "rgba(0,0,0,.03)",
          color: isCurrentEventKey ? "white" : "black",
          padding: ".75rem 1.25rem",
          marginbottom: "0",
          borderbottom: "1px solid rgba(0,0,0,.125)",
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }