
import { VoipBoxCall } from '../../components/VoipBoxCall/index';
import  {Row,Col, Button} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import React,{useContext, useEffect, useState} from 'react'
import Swal from 'sweetalert2';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import caAPI from '../../services/caAPI';
import { ActionCall } from '../../components/ActionCall';
import { VoipModal } from '../../components/VoipModal';
import { Loading } from '../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPhone, faPhoneSlash, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import './../../pages/Call/css/index.css'
import VoipComponent from '../../components/VoipComponent';
export const CallRecord = {


    phone:({state,setContatoDestino,handleCreateNewCallService,setShowVoipModal,setContatosOrigemChosed}) => {

  
        

        return(
            <div color="#F2F3F8" className='call-screen'>
                <div className="row-phone-number">
                    <span>Telefone: +{state.contatoDestino}</span>
                </div>


        
            <div md={12} className='box-select-contato-origem'>
                    <label htmlFor="">Contato Origem</label>
                    <select 
                    name="" 
                    id="" 
                    className='form-control' 
                    onChange={(e) => setContatosOrigemChosed(e.target.value)}>
                            <option value="0">---SELECIONE---</option>
                            {state.contatosOrigem.map((item,index) => (
                                <option value={item.valor} key={index}>{item.descricao}</option>
                            ))}
                    </select>
            </div>
            <VoipBoxCall  setContatoDestino={setContatoDestino}/>

            <ActionCall 
            
                setContatoDestino={setContatoDestino} 
                handleCreateNewCallService={handleCreateNewCallService}
            />
            

            { state.showVoipModal && <VoipModal contatoDestino={state.contatoDestino}  setShowVoipModal={setShowVoipModal}/>}
                
            </div>
        )
    },


    makeCall:(props) => {
        return(<VoipModal 
            atendimento={props.state.atendimento} 
            setContatoDestino={props.setContatoDestino}
            setShowVoipModal={props.setShowVoipModal}
            />)
    },

    loading:() => <Loading/>,
    telefoneEmChamada: () => <VoipComponent/>
}


