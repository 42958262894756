import React, { useMemo, useCallback, useEffect, useRef, useState, useContext, Fragment } from 'react';
import { Tab, Col, Nav, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from "moment";
import caAPI from "../../services/caAPI";
import Swal from 'sweetalert2';
import { trackPromise } from "react-promise-tracker";
import { Icon } from '@iconify/react';
import arrowCircleUpOutline from '@iconify-icons/eva/arrow-circle-up-outline';
import './style.scss';
import Anotacao from './anotacao.js';
import { InputMensagens } from '../InputMensage';
import DommusBase64AudioPlayer from '../DommusBase64AudioPlayer';
import {AtendimentoContext} from '../../contexts/AtendimentoContext';
import { RegistroOutrosAtendimentos } from './RegistroOutrosAtendimentos';
import {isBrowser} from 'react-device-detect';
const disabledMenu = true;

export default function Anotacoes({ handleUpdateListaAnotacoes, showAtendimentosDoDia}) {
    const [listaMensagens, setListaMensagens] = useState();
    const [mensagemInput, setMensagemInput] = useState('');
    const messageContainerRef = useRef();
    const {atendimentoAtivo} = useContext(AtendimentoContext);

    useEffect(() => {
      trackPromise(
          caAPI.atendimento.anotacao.list(atendimentoAtivo?.id).then(res => {
            const listaNova = ( res.data && Array.isArray(res.data) && res.data ) || [];
            console.log(atendimentoAtivo?.detalhes, listaNova);
            setListaMensagens(listaNova);
          }).catch(e => {
              Swal.fire({
                  titleText: "Erro",
                  text: "Houve um problema ao enviar a mensagem " + e,
                  icon: 'error'
              });
          })
      )
    }, [atendimentoAtivo]);

    useEffect(() => {
        if (handleUpdateListaAnotacoes instanceof Function) {
            handleUpdateListaAnotacoes(listaMensagens)
        }
    }, [listaMensagens, handleUpdateListaAnotacoes]);

    const atualizaAnotacao = useCallback(({ mensagem, id }) => {
        trackPromise(
            caAPI.atendimento.anotacao.atualizar(atendimentoAtivo.id, id, mensagem).then(res => {
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
    }, [atendimentoAtivo]);

    const removeAnotacao = useCallback((id_anotacao) => {
        trackPromise(
            caAPI.atendimento.anotacao.deletar(atendimentoAtivo.id, id_anotacao).then(res => {
                setListaMensagens(res.data)
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
    }, [atendimentoAtivo]);


    const mensagens = useMemo(() => {
        if (listaMensagens && Array.isArray(listaMensagens)) {
            return listaMensagens.map(msg => {
                // let detalhes = JSON.parse(msg.detalhes).data;
                return <div key={msg.id}>
                    <Anotacao mensagem={msg} atualizaAnotacao={atualizaAnotacao.bind(this)} removeLista={removeAnotacao.bind(this)} />
                    {/* {msg.anotacao} */}
                </div>
            });
        }
    }, [listaMensagens, atualizaAnotacao, removeAnotacao]);

    // const handleChangeMensagem = useCallback(e => {
    //     setMensagemInput(e.target.value)
    // }, []);
    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }

    const enviarMensagem = useCallback(() => {
        trackPromise(
            caAPI.atendimento.anotacao.enviar(atendimentoAtivo.id, mensagemInput).then(res => {
                setListaMensagens(res.data);
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
        setMensagemInput('');
    }, [atendimentoAtivo, mensagemInput]);

    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    const criarPromiseGravacao = (callSid) => {
      return caAPI.atendimento.voip.buscarGravacao.bind(this, callSid);
    };

    return <>
        <div id='anotacoes'>
            <Tab.Container id="left-tabs" defaultActiveKey="anotacoes">
                <div className='conteudo'>
                    <div className="header">
                        <Nav variant="pills" className="menu_anotacoes overflow-auto">
                            <Col>
                                <Nav.Item>
                                    <Nav.Link eventKey="anotacoes">Anotações</Nav.Link>
                                </Nav.Item>
                            </Col>
                            {showAtendimentosDoDia && <Col>
                                <Nav.Item>
                                    <Nav.Link eventKey="historicoAtendimento">Histórico</Nav.Link>
                                </Nav.Item>
                            </Col>}
                            <Col>
                                <Nav.Item>
                                    <Nav.Link eventKey="registros">{(isBrowser && "Outros ") || ""}Atendimentos</Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="anotacoes">
                            <div className="AnotacoesVoip">
                                <div className="anotacoesContainer">
                                    {mensagens}
                                    <div ref={messageContainerRef}></div>
                                </div>
                                <InputMensagens
                                    disabledMenu={disabledMenu}
                                    mensagemInput={mensagemInput}
                                    enviarMensagem={enviarMensagem}
                                    receberMensagemInicial={enviarMensagem}
                                    atendimento={atendimentoAtivo}
                                    recebeMensagemInput={recebeMensagemInput}
                                    changeSubmitKey={changeSubmitKey} />
                            </div>
                        </Tab.Pane>
                        {showAtendimentosDoDia && <Tab.Pane eventKey="historicoAtendimento">
                            <br />
                            <div className="atendimentosdoDiaContent">
                                <Table className="tebelaAtendimentosdoDiaContent" responsive="sm" striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>ID</th>
                                            <th>
                                                Dia/Hora
                                            </th>
                                            <th>Duração</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {atendimentoAtivo?.conteudos ? atendimentoAtivo.conteudos.map((chamados, index) => {
                                          const detalhesChamado = JSON.parse(chamados.detalhes);
                                          const promiseBuscaAudio = detalhesChamado.CallSid && criarPromiseGravacao(detalhesChamado.CallSid) || null;
                                          return  (
                                            <Fragment key={chamados.id}>
                                              <tr>
                                                  <td>
                                                      {chamados.direcao === "recebido"
                                                          ?
                                                          <OverlayTrigger
                                                              key="buttonRecebido"
                                                              placement="top"
                                                              overlay={
                                                                  <Tooltip id={`tooltip-recebida`}>
                                                                      Recebida
                                                                  </Tooltip>
                                                              }
                                                          >
                                                              <Icon icon={arrowCircleUpOutline} width="2rem" color="#4CAF50" rotate="180deg" />
                                                          </OverlayTrigger>
                                                          :
                                                          <OverlayTrigger
                                                              key="buttonRecebido"
                                                              placement="top"
                                                              overlay={
                                                                  <Tooltip id={`tooltip-realizada`}>
                                                                      Realizada
                                                                  </Tooltip>
                                                              }
                                                          >
                                                              <Icon icon={arrowCircleUpOutline} width="2rem" color="#265d9c" />
                                                          </OverlayTrigger>}
                                                  </td>

                                                  <td>{chamados.id}</td>
                                                  <td>
                                                      {moment(chamados.criado_em).format('DD/MM/YYYY HH:mm')}
                                                  </td>
                                                  <td>{moment.utc(detalhesChamado.duracao * 1000).format('HH:mm:ss')}</td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="4">{promiseBuscaAudio && <DommusBase64AudioPlayer promiseBuscaAudio={promiseBuscaAudio} indice={chamados.id}/>}</td>
                                                </tr>
                                            </Fragment>
                                          )
                                        }) : <></>}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab.Pane>}
                        <Tab.Pane eventKey="registros">
                            <div className="AnotacoesVoip">
                                <RegistroOutrosAtendimentos/>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    </>
}
