import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import formatDommus from "../../../helpers/format";
import caAPI from "../../../services/caAPI";
import DommusTabelaPaginadaControlada from "../../DommusTabelaPaginadaControlada";
import MidiaSocialIcone from "../../MidiaSocialIcone";
import ModalDommus from "../../Modal";
import "./index.scss";
import { MostrarConteudoAtendimentos } from "./MostrarConteudoAtendimentos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export function RegistroOutrosAtendimentos() {
  const { atendimentoAtivo } = useContext(AtendimentoContext);
  const [listaOutrosAtendimentos, setListaOutrosAtendimentos] = useState([]);
  const [tamanhoPagina, setTamanhoPagina] = useState(5);
  const [total, setTotal] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [openModalAtendimento, setOpenModalAtendimento] = useState(false);
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState({});

  const atualizarLista = useCallback(() => {
    let data = {
      contato_destino: atendimentoAtivo?.contato_destino,
      oportunidade: atendimentoAtivo?.oportunidade,
      processo: atendimentoAtivo?.processo,
      page: pagina,
      tamanho_pagina: tamanhoPagina
    };
    trackPromise(caAPI.atendimento.registroOutrosAtendimentos.buscar(data))
      .then((response) => {
        setListaOutrosAtendimentos(response.data.data);
        setTotal(response.data.total);
      })
      .catch((erro) => {
        setListaOutrosAtendimentos([]);
      });
  }, [atendimentoAtivo, pagina, tamanhoPagina]);

  useEffect(() => {
    atualizarLista();
  }, [atendimentoAtivo, pagina, atualizarLista, tamanhoPagina]);

  const handleOpenModalConteudoAtendimento = (item)=>{
    setAtendimentoSelecionado(item);
    setOpenModalAtendimento(true);
  }

  const linhas = useMemo(() => {
    return (
      listaOutrosAtendimentos &&
      listaOutrosAtendimentos.map((item, index) => {
        return (
          <tr
            key={index}
            onClick={() => {
              handleOpenModalConteudoAtendimento(item);
            }}
          >
            <td className="items-rows text-center">
              <div className="meio-comunicacao-indicador">
                <MidiaSocialIcone
                  midia={item.meio_comunicacao?.slug || null}
                />
              </div>
            </td>
            <td className="items-rows text-center">
              {formatDommus.formatDate(item?.criado_em)}
            </td>
          </tr>
        );
      })
    );
  }, [listaOutrosAtendimentos]);

  const thead = (
    <tr>
      <th className="text-center">Mídia:</th>
      <th className="text-center">Data:</th>
    </tr>
  );

  return (
    <>
      <div className="registro-atendimentos-section">
        {linhas.length ?
        <DommusTabelaPaginadaControlada
          linhasCabecalho={thead}
          optionsTabela={{ bordered: false }}
          linhasCorpo={linhas}
          pagina={pagina}
          setPagina={setPagina}
          setTamanhoPagina={setTamanhoPagina}
          tamanhoPagina={tamanhoPagina}
          tamanhoTotal={total}
        />
        :
        <Alert variant="warning" style={{display:"flex", gap:"10px", alignItems:"center"}}>
          <FontAwesomeIcon icon={faExclamationTriangle}/>
          Sem registros de atendimentos!
        </Alert>
        }
      </div>
      <ModalDommus
        open={openModalAtendimento}
        close={() => {
          setOpenModalAtendimento(false);
        }}
        size="md"
        titulo={
          <div className="hearder-modal-conteudo">
            <MidiaSocialIcone midia={atendimentoSelecionado.meio_comunicacao?.slug || null} />
            Registro do Atendimento
          </div>
        }
      >
        <MostrarConteudoAtendimentos atendimento={atendimentoSelecionado}/>
      </ModalDommus>
    </>
  );
}
