import React, {useContext, useEffect} from 'react';
import {useHistory, useParams} from "react-router";
import caAPI from "../../services/caAPI";
import DommusLoading from '../../components/DommusLoading/DommusLoading';
import { useQuery } from '../../hooks/useQuery';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';

export default function GuLogin() {

    const history = useHistory();
    let params = useParams();
    
    const {  setAtendimentoAtivo,atendimentoAtivo } = useContext(AtendimentoContext)

    const query = useQuery();
    useEffect(() => {
        const token = params.token.split(':');
        caAPI.loginGU(token[0]).then(res => {
            caAPI.setLoginInfo(res.data);
            let telas = [];
            let arrayTipoTelas = {
                portal_cliente: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
                template: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
                label: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
                arquivo: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
                quadro_historico: [1, 2, 3, 4],
                integracoes: [1, 2, 3, 4],
                filtro_interacoes: [1, 2, 3, 4],
                filtro_notificacoes: [1, 2, 3, 4],
                relatorios_diversos: [1, 2, 3, 4],
                dashboard: [1, 2, 3, 4]
            };
            for (const [tela, tipos] of Object.entries(arrayTipoTelas)) {
                if (tipos.includes(Number(res.data.id_usuario_tipo))) {
                    telas.push(tela)
                }
            }

            console.log(token)
            caAPI.setLocalState('telas', telas);
            history.push(`/${token[1] ? `?idAtendimento=${token[1]}` : ''}`);
            const screen = query.get('screen')
            let atendimento = query.get('atendimento')


            if(atendimento){
                setAtendimentoAtivo(atendimento)
            }


            if(screen){
                window.location.href = `/?screen=makeCall&atendimento=${atendimento}`
                return
            }
            window.location.href = '/';
        }).catch(err => alert(err));

    }, [history, params]);


    return <DommusLoading/>;
}
